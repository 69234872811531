@charset "utf-8";

/*
Author: Joshua Lawrinenko
Date: 5/2/2020
File: reset.css
Description: Resets the browser and gives clean slate for style sheets
*/

article, aside, figcaption, figure,
footer, header, main, nav, section {
	display: block;
}

address, article, aside, blockquote, body, cite,
div, dl, dt, dd, em, figcaption, figure, footer,
h1, h2, h3, h4, h5, h6, header, html, img,
li, main, nav, ol, p, section, span, ul {
	background: transparent;
	font-size: 100%;
	margin: 0;
	padding: 0;
	vertical-align: baseline;
	-webkit-box-sizing: border-box; /* changes from content-box model to border-box model */
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	line-height: 1; 
}

nav ul {
	list-style: none;
	list-style-image: none;
}

nav a {
	text-decoration: none;
}
